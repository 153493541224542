import React from 'react'
import { Container, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { LandingPage } from '../../types/landingPage'
import LandingPageSection from '../LandingPage/LandingPageSection'
import CallToAction from '../CallToAction'
import XRayWrapper from '../XRay/XRayWrapper'

interface Props {
  landingPage: LandingPage
}

const LandingPageTemplate: React.FC<Props> = ({ landingPage }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.container} component="article">
      <Container className={classes.containerInner}>
        <XRayWrapper id={landingPage.sections[0].contentful_id}>
          <LandingPageSection
            section={landingPage.sections[0]}
            limit={7}
            layout="flex"
          />
        </XRayWrapper>
        {landingPage.cta && (
          <XRayWrapper id={landingPage.contentful_id}>
            <CallToAction cta={landingPage.cta} />
          </XRayWrapper>
        )}
        {landingPage.sections[1] && (
          <XRayWrapper id={landingPage.sections[1].contentful_id}>
            <LandingPageSection
              section={landingPage.sections[1]}
              limit={3}
              layout="grid"
            />
          </XRayWrapper>
        )}
        {landingPage.sections[2] && (
          <XRayWrapper id={landingPage.sections[2].contentful_id}>
            <LandingPageSection
              section={landingPage.sections[2]}
              limit={3}
              layout="grid"
            />
          </XRayWrapper>
        )}
      </Container>
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  container: {
    maxWidth: '100%',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    paddingBottom: '4.5em',
    paddingLeft: '0px!important',
    paddingRight: '0px!important',
  },
  containerInner: {
    maxWidth: '100%',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    paddingLeft: '0px!important',
    paddingRight: '0px!important',
  },
}))

export default LandingPageTemplate
