import { PageProps, graphql } from 'gatsby'
import React from 'react'

import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'

import SearchSection from '@talentinc/gatsby-theme-ecom/components/LandingPage/SearchSection'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import BlogHomePageSEO from '@talentinc/gatsby-theme-ecom/components/SEO/BlogHomePageSEO'
import BaseLandingPageTemplate from '@talentinc/gatsby-theme-ecom/components/Templates/LandingPage'
import { CategoryWithSEO } from '@talentinc/gatsby-theme-ecom/types/category'
import { LandingPage } from '@talentinc/gatsby-theme-ecom/types/landingPage'
import {
  PageContextType,
  PageDataContextType,
} from '@talentinc/gatsby-theme-ecom/types/page'

import Footer from '../components/Footer'
import HeaderV2 from '../components/HeaderV2'

type Props = PageProps<
  {
    landingPage: LandingPage
    categories: {
      nodes: CategoryWithSEO[]
    }
  } & PageDataContextType,
  PageContextType
>

const LandingPageTemplate: React.FC<Props> = (props) => {
  const { landingPage, ...pageData } = props.data
  return (
    <Layout pageTitle={landingPage.title} pageData={pageData}>
      <SEO landingPage={landingPage} noIndex={props.pageContext.noIndex} />
      {landingPage.slug === pageData.brand.blogPrefix && (
        <BlogHomePageSEO categories={pageData.categories.nodes} />
      )}
      <HeaderV2 showBlogNavigation />
      <SearchSection />
      <BaseLandingPageTemplate landingPage={landingPage} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query LandingPagesBySlugAndBrand($slug: String!, $brandName: String!) {
    landingPage: contentfulLandingPage(
      slug: { eq: $slug }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...LandingPage
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    categories: allContentfulCategory(
      filter: { brand: { elemMatch: { name: { eq: $brandName } } } }
    ) {
      nodes {
        ...Category
        seo {
          description
        }
      }
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default LandingPageTemplate
