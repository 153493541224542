import React from 'react'
import { Helmet } from 'react-helmet'
import { helmetJsonLdProp } from 'react-schemaorg'
import { CollectionPage } from 'schema-dts'
import { CategoryWithSEO } from '../../types/category'
import useUrlPathBuilder from '../../hooks/useUrlPathBuilder'
import { insertHandlebarsTemplate } from '../../utils/insertHandlebarsTemplate'
import useHandlebarsContext from '../../hooks/useHandlebarsContext'

interface Props {
  categories: CategoryWithSEO[]
}

const BlogHomePageSEO: React.FC<Props> = ({ categories }) => {
  const { urlPathBuilder, baseURL, blogPrefix } = useUrlPathBuilder()
  const handlebarsContext = useHandlebarsContext()
  return (
    <Helmet
      script={[
        helmetJsonLdProp<CollectionPage>({
          '@context': 'https://schema.org',
          '@type': 'CollectionPage',
          mainEntity: {
            '@type': 'ItemList',
            numberOfItems: categories.length,
            itemListElement: categories.map((category, i) => ({
              '@type': 'ListItem',
              position: i + 1,
              item: {
                '@type': 'WebPage',
                url: urlPathBuilder([blogPrefix, category.slug], {
                  baseURL,
                }),
                name: category.name,
                description: insertHandlebarsTemplate(
                  category?.seo?.description ?? undefined,
                  handlebarsContext
                ),
                publisher: {
                  '@id': urlPathBuilder([], {
                    baseURL,
                    hash: 'organization',
                  }),
                },
              },
            })),
          },
        }),
      ]}
    />
  )
}

export default BlogHomePageSEO
